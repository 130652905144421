export class PlayroomQuestionDto {
  constructor() {
    this.question = '';
    this.answer = '';
  }
  id!: string;
  sortOrder!: number;
  playroomId!: string;
  question!: string;
  answer!: string;
}
