import { helpers } from 'vuelidate/lib/validators';

const germanMinValueValidator = (min: number) =>
  helpers.withParams({ type: 'minValue', min }, (value: string) => {
    if (value == undefined) return false;
    value = value.toString();
    value = value.replace(',', '.');
    const numberValue = parseFloat(value);
    return min <= numberValue;
  });

export default germanMinValueValidator;
