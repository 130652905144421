import { helpers } from 'vuelidate/lib/validators';

const germanMaxValueValidator = (max: number) =>
  helpers.withParams({ type: 'maxValue', max }, (value: string) => {
    if (value == undefined) return false;
    value = value.toString();
    value = value.replace(',', '.');
    const numberValue = parseFloat(value);
    return max >= numberValue;
  });

export default germanMaxValueValidator;
