








































































































































































































import Vue, { VueConstructor } from 'vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import OwnerPlayroomCancellationRulesService from '@/services/owner/OwnerPlayroomCancellationRulesService';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import germanMinValueValidator from '@/validators/germanMinValueValidator';
import germanMaxValueValidator from '@/validators/germanMaxValueValidator';
import greaterOrEqualFieldBefore from '@/validators/greaterOrEqualFieldBefore';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

class OvernightCancellationRules {
  before30Days!: number;
  before14Days!: number;
  before5Days!: number;
  before3Days!: number;
  before1Days!: number;
  beforeSameDay!: number;
}

class HourlyCancellationRules {
  before30Days!: number;
  before14Days!: number;
  before5Days!: number;
  before3Days!: number;
  before1Days!: number;
  before5hours!: number;
  before3hours!: number;
  before1hours!: number;
}

class PlayroomEditCancellationRulesViewModel {
  constructor() {
    this.id = '';
    this.hourlyCancellationRules = {
      before30Days: 0,
      before14Days: 0,
      before5Days: 0,
      before3Days: 0,
      before1Days: 0,
      before5hours: 0,
      before3hours: 0,
      before1hours: 0,
    };
    this.overnightCancellationRules = {
      before30Days: 0,
      before14Days: 0,
      before5Days: 0,
      before3Days: 0,
      before1Days: 0,
      beforeSameDay: 0,
    };
  }
  id: string;
  hourlyCancellationRules!: HourlyCancellationRules;
  overnightCancellationRules!: OvernightCancellationRules;
  allowHourBooking!: boolean;
  allowOvernightStay!: boolean;
}

const validations = {
  playroom: {
    hourlyCancellationRules: {
      before30Days: {
        required,
        greaterOrEqualFieldBefore: germanMinValueValidator(0),
        maxValue: germanMaxValueValidator(100),
      },
      before14Days: {
        required,
        greaterOrEqualFieldBefore: greaterOrEqualFieldBefore('before30Days'),
        maxValue: germanMaxValueValidator(100),
      },
      before5Days: {
        required,
        greaterOrEqualFieldBefore: greaterOrEqualFieldBefore('before14Days'),
        maxValue: germanMaxValueValidator(100),
      },
      before3Days: {
        required,
        greaterOrEqualFieldBefore: greaterOrEqualFieldBefore('before5Days'),
        maxValue: germanMaxValueValidator(100),
      },
      before1Days: {
        required,
        greaterOrEqualFieldBefore: greaterOrEqualFieldBefore('before3Days'),
        maxValue: germanMaxValueValidator(100),
      },
      before5hours: {
        required,
        greaterOrEqualFieldBefore: greaterOrEqualFieldBefore('before1Days'),
        maxValue: germanMaxValueValidator(100),
      },
      before3hours: {
        required,
        greaterOrEqualFieldBefore: greaterOrEqualFieldBefore('before5hours'),
        maxValue: germanMaxValueValidator(100),
      },
      before1hours: {
        required,
        greaterOrEqualFieldBefore: greaterOrEqualFieldBefore('before3hours'),
        maxValue: germanMaxValueValidator(100),
      },
    },
    overnightCancellationRules: {
      before30Days: {
        required,
        greaterOrEqualFieldBefore: germanMinValueValidator(0),
        maxValue: germanMaxValueValidator(100),
      },
      before14Days: {
        required,
        greaterOrEqualFieldBefore: greaterOrEqualFieldBefore('before30Days'),
        maxValue: germanMaxValueValidator(100),
      },
      before5Days: {
        required,
        greaterOrEqualFieldBefore: greaterOrEqualFieldBefore('before14Days'),
        maxValue: germanMaxValueValidator(100),
      },
      before3Days: {
        required,
        greaterOrEqualFieldBefore: greaterOrEqualFieldBefore('before5Days'),
        maxValue: germanMaxValueValidator(100),
      },
      before1Days: {
        required,
        greaterOrEqualFieldBefore: greaterOrEqualFieldBefore('before3Days'),
        maxValue: germanMaxValueValidator(100),
      },
      beforeSameDay: {
        required,
        greaterOrEqualFieldBefore: greaterOrEqualFieldBefore('before1Days'),
        maxValue: germanMaxValueValidator(100),
      },
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedInput, ValidatedFormCard },
  mixins: [validationMixin, HelpCardMixin],
  validations() {
    const vals = {
      playroom: {
        hourlyCancellationRules: {
          before30Days: {},
          before14Days: {},
          before5Days: {},
          before3Days: {},
          before1Days: {},
          before5hours: {},
          before3hours: {},
          before1hours: {},
        },
        overnightCancellationRules: {
          before30Days: {},
          before14Days: {},
          before5Days: {},
          before3Days: {},
          before1Days: {},
          beforeSameDay: {},
        },
      },
    };
    if (this.playroom.allowHourBooking) {
      vals.playroom.hourlyCancellationRules = validations.playroom.hourlyCancellationRules;
    }
    if (this.playroom.allowOvernightStay) {
      vals.playroom.overnightCancellationRules = validations.playroom.overnightCancellationRules;
    }
    return vals;
  },
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditCancellationRulesViewModel,
      ready: false,
    };
  },
  mounted() {
    this.loadData();
    this.ready = false;
  },
  methods: {
    loadData() {
      OwnerPlayroomCancellationRulesService.getCancellationRulesPart(this.playroomId).then((res: any) => {
        this.playroom.id = this.playroomId;
        this.playroom = new PlayroomEditCancellationRulesViewModel();
        const hourlyCancellationRules = this.playroom.hourlyCancellationRules as any;
        const overnightCancellationRules = this.playroom.overnightCancellationRules as any;
        this.playroom.allowHourBooking = res.value.allowHourBooking;
        this.playroom.allowOvernightStay = res.value.allowOvernightStay;
        this.playroom.id = res.value.id;
        for (let index = 0; index < res.value.cancellationRules.length; index++) {
          let rule = res.value.cancellationRules[index];
          if (rule.cancellationRulesType == 1) {
            hourlyCancellationRules[rule.beforeStart] = rule.percentage;
          } else if (rule.cancellationRulesType == 2) {
            overnightCancellationRules[rule.beforeStart] = rule.percentage;
          }
        }
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.CancellationRules.info.box1';
      }
    },

    getHoursBeforeStart(key: string) {
      switch (key) {
        case 'before30Days':
          return 30 * 24;
        case 'before14Days':
          return 14 * 24;
        case 'before5Days':
          return 5 * 24;
        case 'before3Days':
          return 3 * 24;
        case 'before1Days':
          return 24;
        case 'before5hours':
          return 5;
        case 'before3hours':
          return 3;
        case 'before1hours':
          return 1;
        case 'beforeSameDay':
          return 0;
      }
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const hourlyCancellationRules = this.playroom.hourlyCancellationRules as any;
        let hourlyRules = Object.keys(hourlyCancellationRules).map((key: string) => {
          return {
            playroomId: this.playroomId,
            cancellationRulesType: 1,
            beforeStart: key,
            percentage: hourlyCancellationRules[key].toString(),
            hoursBeforeStart: this.getHoursBeforeStart(key)?.toString(),
          };
        });
        const overnightCancellationRules = this.playroom.overnightCancellationRules as any;
        let overnightRules = Object.keys(overnightCancellationRules).map((key: string) => {
          return {
            playroomId: this.playroomId,
            cancellationRulesType: 2,
            beforeStart: key,
            percentage: overnightCancellationRules[key].toString(),
            hoursBeforeStart: this.getHoursBeforeStart(key)?.toString(),
          };
        });

        var response = await OwnerPlayroomCancellationRulesService.editCancellationRulesPart(
          hourlyRules.concat(overnightRules),
          this.playroomId
        );
        if (response.success) {
          this.$emit('part-save-status-type-change', PartSaveStatusType.Saved);
          this.$v.$reset();
        }
        return response.success;
      } else {
        return false;
      }
    },
  },
});
