import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/PlayroomBasicRules/';

class OwnerPlayroomBasicRulesService {
  Create(playroom: { basicRules: {}; maxPersonsOvernight: string; maxPersonsHourly: string }, playroomId: string) {
    return ApiService.post(`${API_URL}EditBasicRulesPart?playroomId=${playroomId}`, {
      ...playroom,
    });
  }

  getBasicRulesPart(playroomId: string) {
    return ApiService.get(`${API_URL}GetBasicRulesPart?playroomId=${playroomId}`);
  }
}

export default new OwnerPlayroomBasicRulesService();
