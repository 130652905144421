import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/PlayroomCancellationRules/';

class OwnerPlayroomCancellationRulesService {
  editCancellationRulesPart(cancellationRules: {}[], playroomId: string) {
    return ApiService.post(`${API_URL}EditCancellationRulesPart?playroomId=${playroomId}`, cancellationRules);
  }

  getCancellationRulesPart(playroomId: string) {
    return ApiService.get(`${API_URL}GetCancellationRulesPart?playroomId=${playroomId}`);
  }
}

export default new OwnerPlayroomCancellationRulesService();
