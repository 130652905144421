





































































































































































import Vue from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import ErrorMessage from '@/components/shared/ErrorMessage.vue';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { validationMixin } from 'vuelidate';
import RuleUpload from './RuleUpload.vue';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { PlayroomFileViewModel } from '@/models/PlayroomDetailDto';
import { required } from 'vuelidate/lib/validators';

const validations = {
  businessTermsFile: {
    type: {},
    link: {},
    fileName: { required },
    dataUrl: {},
  },
  houseRulesFile: {
    type: {},
    link: {},
    fileName: {},
    dataUrl: {},
  },
  dataPrivacyPolicyFile: {
    type: {},
    link: {},
    fileName: {},
    dataUrl: {},
  },
  imprintRulesFile: {
    type: {},
    link: {},
    fileName: {},
    dataUrl: {},
  },
};

export default Vue.extend({
  components: { RuleUpload, ErrorMessage, ValidatedFormCard },
  validations,
  mixins: [validationMixin],
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      businessTermsFile: {} as PlayroomFileViewModel,
      houseRulesFile: {} as PlayroomFileViewModel,
      dataPrivacyPolicyFile: {} as PlayroomFileViewModel,
      imprintRulesFile: {} as PlayroomFileViewModel,
      playroomFiles: {} as PlayroomFileViewModel[],
      errorMessage: '',
      ready: false,
    };
  },
  async mounted() {
    await this.loadData();
  },
  computed: {
    hasBusinessTerms(): boolean {
      return !this.$v.$anyDirty || this.businessTermsFile.fileName != '';
    },
  },
  methods: {
    async loadData() {
      this.ready = false;
      var response = await OwnerPlayroomService.getForEditHouseRulesAndBusinessTermsPart(this.playroomId);
      if (response) {
        this.playroomFiles = response.value as PlayroomFileViewModel[];

        let arrfiles = this.playroomFiles.filter((f) => {
          return f.type == 'HouseRules';
        });
        this.houseRulesFile = this.setObject(arrfiles, 'HouseRules');

        arrfiles = this.playroomFiles.filter((f) => {
          return f.type == 'GeneralBusinessTerms';
        });
        this.businessTermsFile = this.setObject(arrfiles, 'GeneralBusinessTerms');
        arrfiles = this.playroomFiles.filter((f) => {
          return f.type == 'DataPrivacyPolicy';
        });
        this.dataPrivacyPolicyFile = this.setObject(arrfiles, 'DataPrivacyPolicy');

        arrfiles = this.playroomFiles.filter((f) => {
          return f.type == 'Imprint';
        });
        this.imprintRulesFile = this.setObject(arrfiles, 'Imprint');

        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      }
      this.ready = true;
    },
    setObject(arrfiles: PlayroomFileViewModel[], type: string) {
      if (arrfiles.length > 0) {
        return arrfiles[0];
      } else {
        const tmpObj = {} as PlayroomFileViewModel;
        tmpObj.id = '';
        tmpObj.fileName = '';
        tmpObj.playroomId = this.playroomId;
        tmpObj.link = '';
        tmpObj.type = type;
        this.playroomFiles.push(tmpObj);
        return tmpObj;
      }
    },

    handleInput(input: HTMLInputElement, playroomFile: PlayroomFileViewModel, fileObject: any) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        // Success Read Callback
        reader.onload = (e: any) => {
          this.ready = false;
          playroomFile.dataUrl = e.target.result;
          this.ready = true;
        };

        // Read first file
        reader.readAsDataURL(input.files[0]); // convert to base64 string
        fileObject.fileName.$model = input.files[0].name;
        this.$v.$touch();
      }
    },
    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.ready = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveHouseRulesAndBusinessTermsPart({
          playroomFiles: this.playroomFiles,
          playroomId: this.playroomId,
        });
        if (response.success) {
          await this.loadData();
        }
        return response.success;
      } else {
        this.ready = true;
        return false;
      }
    },
    deleteFile(input: HTMLInputElement, playroomFile: PlayroomFileViewModel, fileObject: any) {
      this.ready = false;
      if (fileObject.fileName) {
        fileObject.fileName.$model = '';
      }
      playroomFile.link = '';
      playroomFile.fileName = '';
      playroomFile.dataUrl = '';
      if (input) {
        input.value = '';
      }
      this.$nextTick(() => {
        this.ready = true;
      });
    },

    dropFile(event: DragEvent, input: HTMLInputElement, playroomFile: PlayroomFileViewModel, fileObject: any) {
      if (event.dataTransfer && event.dataTransfer.files.item(0)?.name.endsWith('.pdf')) {
        input.files = event.dataTransfer.files;
        this.handleInput(input, playroomFile, fileObject);
      }
    },
  },
});
