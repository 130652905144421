

































































import Vue, { VueConstructor } from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { validationMixin } from 'vuelidate';
import draggable from 'vuedraggable';
import { PlayroomQuestionDto } from '@/models/PlayroomQuestionDto';
import { maxLength, required } from 'vuelidate/lib/validators';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import DeleteButton from '@/components/forms/DeleteButton.vue';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { ServerResponse } from '@/services/ApiService';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

class PlayroomEditFrequentlyAskedQuestionsViewModel {
  id!: string;
  questions!: PlayroomQuestionDto[];
}

const validations = {
  playroom: {
    questions: {
      $each: {
        question: {
          maxLength: maxLength(180),
          required,
        },
        answer: {
          maxLength: maxLength(750),
          required,
        },
      },
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: {
    ValidatedFormCard,
    draggable,
    ValidatedTextArea,
    DeleteButton,
    ValidatedInput,
  },
  validations,
  mixins: [validationMixin, HelpCardMixin],
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: '',
      ready: false,
      playroom: {} as PlayroomEditFrequentlyAskedQuestionsViewModel,
      showDeleteModel: false,
      rowIndex: '',
    };
  },
  async mounted() {
    await this.loadData();
  },
  computed: {
    stillAvailableCount(): number {
      return 10 - this.playroom.questions.length;
    },
    canAdd(): boolean {
      return !this.$v.$invalid && this.stillAvailableCount > 0;
    },
  },
  methods: {
    async loadData(): Promise<ServerResponse> {
      var response = await OwnerPlayroomService.getQuestion(this.playroomId);
      if (response) {
        this.playroom = response.value as PlayroomEditFrequentlyAskedQuestionsViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      }
      return response;
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.FrequentlyAskedQuestions.info.box1';
      }
    },

    addNewQuestion(): void {
      if (this.canAdd) {
        this.playroom.questions.push(new PlayroomQuestionDto());
      }
    },

    onDeletedQuestion(index: string) {
      this.showDeleteModel = false;
      this.playroom.questions.splice(+index, 1);
      this.$v.$touch();
    },

    deleteQuestion(id: number) {
      this.rowIndex = id.toString();
      this.showDeleteModel = true;
    },

    closeDeleteModel() {
      this.showDeleteModel = false;
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.ready = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.playroom.questions.forEach((item, index) => {
          item.sortOrder = index + 1;
        });
        var response = await OwnerPlayroomService.saveFrequentlyAskedQuestions({ ...this.playroom });
        if (response.success) {
          await this.loadData();
        }
        return response.success;
      } else {
        return false;
      }
    },
  },
});
