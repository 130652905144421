import { helpers } from 'vuelidate/lib/validators';

const greaterOrEqualFieldBefore = (field: string) => {
  return helpers.withParams(
    { type: 'greaterOrEqualFieldBefore' },
    (value: string, cancellationRules: { [n: string]: string }) => {
      if (value == undefined) return false;
      value = value.toString();
      value = value.replace(',', '.');
      const numberValue = parseFloat(value);
      const min = parseFloat(cancellationRules[field]);
      return min <= numberValue;
    }
  );
};

export default greaterOrEqualFieldBefore;
