

















































































import Vue from 'vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import OwnerBasicRulesService from '@/services/owner/OwnerPlayroomBasicRulesService';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { validationMixin } from 'vuelidate';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import { maxValue, minValue, numeric, required } from 'vuelidate/lib/validators';

class PlayroomEditBasicRulesPartViewModel {
  id!: string;
  smokingAllowed!: boolean;
  smokingAllowedIndoor!: boolean;
  petsAllowed!: boolean;
  partyEventAllowed!: boolean;
  commercialImageUsageAllowed!: boolean;
  commercialServiceUsageAllowed!: boolean;
  maxPersonsOvernight!: number;
  maxPersonsHourly!: number;
}
export default Vue.extend({
  components: { ValidatedFormCard, ValidatedCheckbox, ValidatedInput },
  validations() {
    const validations = {
      basicRules: {
        smokingAllowed: {},
        smokingAllowedIndoor: {},
        petsAllowed: {},
        partyEventAllowed: {},
        commercialImageUsageAllowed: {},
        commercialServiceUsageAllowed: {},
      },
      maxPersonsHourly: {},
      maxPersonsOvernight: {},
    };
    if (this.allowHourBooking) {
      validations.maxPersonsHourly = {
        required,
        minValue: minValue(2),
        maxValue: maxValue(100),
        numeric,
      };
    }
    if (this.allowOvernightStay) {
      validations.maxPersonsOvernight = {
        required,
        minValue: minValue(2),
        maxValue: maxValue(100),
        numeric,
      };
    }
    return validations;
  },
  mixins: [validationMixin],
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      basicRules: {} as PlayroomEditBasicRulesPartViewModel,
      maxPersonsOvernight: 0,
      maxPersonsHourly: 0,
      allowOvernightStay: false,
      allowHourBooking: false,
      ready: false,
      errorMessage: null as string | null,
    };
  },
  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      OwnerBasicRulesService.getBasicRulesPart(this.playroomId).then((res) => {
        this.basicRules = res.value as PlayroomEditBasicRulesPartViewModel;
        this.maxPersonsOvernight = res.value.maxPersonsOvernight;
        this.maxPersonsHourly = res.value.maxPersonsHourly;
        this.allowOvernightStay = res.value.allowOvernightStay;
        this.allowHourBooking = res.value.allowHourBooking;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerBasicRulesService.Create(
          {
            basicRules: {
              smokingAllowed: this.basicRules.smokingAllowed.toString(),
              smokingAllowedIndoor: this.basicRules.smokingAllowedIndoor.toString(),
              petsAllowed: this.basicRules.petsAllowed.toString(),
              partyEventAllowed: this.basicRules.partyEventAllowed.toString(),
              commercialImageUsageAllowed: this.basicRules.commercialImageUsageAllowed.toString(),
              commercialServiceUsageAllowed: this.basicRules.commercialServiceUsageAllowed.toString(),
            },
            maxPersonsOvernight: this.maxPersonsOvernight.toString(),
            maxPersonsHourly: this.maxPersonsHourly.toString(),
          },
          this.playroomId
        );
        if (response.success) {
          this.$emit('part-save-status-type-change', PartSaveStatusType.Saved);
          this.$v.$reset();
        }
        return response.success;
      } else {
        return false;
      }
    },
  },
});
