





























import Vue from 'vue';
import DeleteButton from '@/components/forms/DeleteButton.vue';

export default Vue.extend({
  components: { DeleteButton },
  props: {
    link: {
      type: String,
      required: false,
    },
    dataUrl: {
      type: String,
      required: false,
    },
    fileName: {
      type: String,
      required: false,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasLink(): boolean {
      return !!this.link;
    },
    hasDataUrl(): boolean {
      return !!this.dataUrl;
    },
  },
});
